// THEMES
$themes: (
    default: (
        base-color: #fafbfd,
        base-color-transparent: #fafbfdbd,
        base-contrast-color: #e6e9f1,
        base-contrast-two-color: #f1f2f7,
        base-contrast-alt-color: white,
        primary-color: #bb2b18,
        primary-contrast-color: white,
        secondary-color: #333333,
        secondary-contrast-color: white,
        secondary-contrast-soft-color: #cfcfcf,
        secondary-contrast-softer-color: #a7a7a7,
        secondary-contrast-softest-color: #444444,
        text-color: #556464,
        text-soft-color: #acacb5,
        text-softest-color: #d6d6db,
        border-color: #c8cdd9,
        border-color-light: #c8cdd9,
        disabled-color: #f0f1f5,
        logo-contrast-color: #333333,
        overlay-color: #3a3c4085,
        warning-color: #a1a106,
        placeholder-color: #7a7a7a,
        graph-color-yellow: #bfcf30,
        graph-color-yellow-alt: #e9eea3,
        graph-color-green: #299764,
        graph-color-green-alt: #a1cfbc,
        graph-color-grey: #747474,
        graph-color-red: #c0623d,
        graph-color-red-alt: #eba98e,
        chart-color-blue-light: #8fdaff,
        chart-color-blue-dark: #0070ff,
        chart-color-red-light: #ffc0cb,
        chart-color-red-dark: #e11f41,
        chart-color-green-light: #c6ffc6,
        chart-color-green-dark: #09bb83,
        chart-color-yellow-light: #fff6af,
        chart-color-yellow-dark: #ffae00,
        chart-color-grey-light: #e9e9e9,
        chart-color-grey-dark: #ababab,
    ),
    legacy: (
        base-color: #dcdcdc,
        base-color-transparent: #dcdcdccb,
        base-contrast-color: #d1cdcd,
        base-contrast-two-color: #d9d9d9,
        base-contrast-alt-color: #ffffff,
        primary-color: #bb2b18,
        primary-contrast-color: #dcdcdc,
        secondary-color: #333333,
        secondary-contrast-color: #dcdcdc,
        secondary-contrast-soft-color: #d9d3d3,
        secondary-contrast-softer-color: #ada8a8,
        secondary-contrast-softest-color: #444444,
        text-color: #47443d,
        text-soft-color: #47443d,
        text-softest-color: #666259,
        border-color: #bbb5b5,
        border-color-light: #bbb5b5,
        disabled-color: #d5d1d1,
        logo-contrast-color: #333333,
        overlay-color: #34313185,
        warning-color: #a77300,
        placeholder-color: #7a7a7a,
        graph-color-yellow: #e2d240,
        graph-color-yellow-alt: #d5d0bc,
        graph-color-green: #209f44,
        graph-color-green-alt: #bdc5c2,
        graph-color-grey: #515151,
        graph-color-red: #aa3c3c,
        graph-color-red-alt: #cfb4b4,
        chart-color-blue-light: #8ebfff,
        chart-color-blue-dark: #0063e6,
        chart-color-red-light: #da8795,
        chart-color-red-dark: #ca0c2f,
        chart-color-green-light: rgb(146, 218, 146),
        chart-color-green-dark: rgb(0, 153, 59),
        chart-color-yellow-light: #e0d687,
        chart-color-yellow-dark: #e2b900,
        chart-color-grey-light: #b4b4b4,
        chart-color-grey-dark: #727272,
    ),
    dark: (
        base-color: #36393f,
        base-color-transparent: #36393fc5,
        base-contrast-color: #2f3136,
        base-contrast-two-color: #33363c,
        base-contrast-alt-color: #40444b,
        primary-color: #cd5363,
        primary-contrast-color: white,
        secondary-color: #202225,
        secondary-contrast-color: #dddfe3,
        secondary-contrast-soft-color: #cfcfcf,
        secondary-contrast-softer-color: #999a9d,
        secondary-contrast-softest-color: #2f3136,
        text-color: #b9bbbe,
        text-soft-color: #6b727c,
        text-softest-color: #575b63,
        border-color: #42464d,
        border-color-light: #555a62,
        disabled-color: #34383e,
        logo-contrast-color: #b9bbbe,
        overlay-color: #181b2094,
        warning-color: #bfa836,
        placeholder-color: #7a7a7a,
        graph-color-yellow: #ae9851,
        graph-color-yellow-alt: #494b45,
        graph-color-green: #348c73,
        graph-color-green-alt: #495253,
        graph-color-grey: #595b61,
        graph-color-red: #aa3c3c,
        graph-color-red-alt: #534242,
        chart-color-blue-light: #1f4980,
        chart-color-blue-dark: #129ee4,
        chart-color-red-light: #692b36,
        chart-color-red-dark: #ff395d,
        chart-color-green-light: #4b8067,
        chart-color-green-dark: #00ffae,
        chart-color-yellow-light: #8b8b52,
        chart-color-yellow-dark: rgb(255, 230, 39),
        chart-color-grey-light: #424242,
        chart-color-grey-dark: #6d6d6d,
    ),
    henrik: (
        base-color: #fafbfd,
        base-color-transparent: #fafbfdbd,
        base-contrast-color: #e6e9f1,
        base-contrast-two-color: #f1f2f7,
        base-contrast-alt-color: white,
        primary-color: #bb2b18,
        primary-contrast-color: white,
        secondary-color: #333333,
        secondary-contrast-color: white,
        secondary-contrast-soft-color: #cfcfcf,
        secondary-contrast-softer-color: #a7a7a7,
        secondary-contrast-softest-color: #444444,
        text-color: #000000,
        text-soft-color: #313131,
        text-softest-color: #535353,
        border-color: #bbbbbb,
        border-color-light: #a5a5a5,
        disabled-color: #f0f1f5,
        logo-contrast-color: #333333,
        overlay-color: #3a3c4085,
        warning-color: #a1a106,
        placeholder-color: #7a7a7a,
        graph-color-yellow: #bfcf30,
        graph-color-yellow-alt: #e9eea3,
        graph-color-green: #299764,
        graph-color-green-alt: #a1cfbc,
        graph-color-grey: #858585,
        graph-color-red: #c0623d,
        graph-color-red-alt: #eba98e,
        chart-color-blue-light: #8fdaff,
        chart-color-blue-dark: #0070ff,
        chart-color-red-light: #ffc0cb,
        chart-color-red-dark: #e11f41,
        chart-color-green-light: #c6ffc6,
        chart-color-green-dark: #09bb83,
        chart-color-yellow-light: #fff5a5,
        chart-color-yellow-dark: #ffe200,
        chart-color-grey-light: #e9e9e9,
        chart-color-grey-dark: #ababab,
    ),
);


$default_map: map-get($themes, "default");

@mixin defaultColors {
    @each $name, $color in $default_map {
        --#{$name}: #{$color};
    }
};

@function get-color($key, $theme) {
    @return map-get(map-get($themes, $theme), $key);
}



:export {
    theme: {
        default: {
            base-color: get-color("base-color", "default");
            base-color-transparent: get-color("base-color-transparent", "default");
            base-contrast-color: get-color("base-contrast-color", "default");
            base-contrast-two-color: get-color("base-contrast-two-color", "default");
            base-contrast-alt-color: get-color("base-contrast-alt-color", "default");
            primary-color: get-color("primary-color", "default");
            primary-contrast-color: get-color("primary-contrast-color", "default");
            secondary-color: get-color("secondary-color", "default");
            secondary-contrast-color: get-color("secondary-contrast-color", "default");
            secondary-contrast-soft-color: get-color("secondary-contrast-soft-color", "default");
            secondary-contrast-softer-color: get-color("secondary-contrast-softer-color", "default");
            secondary-contrast-softest-color: get-color("secondary-contrast-softest-color", "default");
            text-color: get-color("text-color", "default");
            text-soft-color: get-color("text-soft-color", "default");
            text-softest-color: get-color("text-softest-color", "default");
            border-color: get-color("border-color", "default");
            border-color-light: get-color("border-color-light", "default");
            logo-contrast-color: get-color("logo-contrast-color", "default");
            disabled-color: get-color("disabled-color", "default");
            overlay-color: get-color("overlay-color", "default");
            white-color: get-color("white-color", "default");
            black-color: get-color("black-color", "default");
            warning-color: get-color("warning-color", "default");
            placeholder-color: get-color("placeholder-color", "default");
            graph-color-yellow: get-color("graph-color-yellow", "default");
            graph-color-yellow-alt: get-color("graph-color-yellow-alt", "default");
            graph-color-green: get-color("graph-color-green", "default");
            graph-color-green-alt: get-color("graph-color-green-alt", "default");
            graph-color-grey: get-color("graph-color-grey", "default");
            graph-color-red: get-color("graph-color-red", "default");
            graph-color-red-alt: get-color("graph-color-red-alt", "default");
            chart-color-blue-light: get-color("chart-color-blue-light", "default");
            chart-color-blue-dark: get-color("chart-color-blue-dark", "default");
            chart-color-red-light: get-color("chart-color-red-light", "default");
            chart-color-red-dark: get-color("chart-color-red-dark", "default");
            chart-color-green-light: get-color("chart-color-green-light", "default");
            chart-color-green-dark: get-color("chart-color-green-dark", "default");
            chart-color-yellow-light: get-color("chart-color-yellow-light", "default");
            chart-color-yellow-dark: get-color("chart-color-yellow-dark", "default");
            chart-color-grey-light: get-color("chart-color-grey-light", "default");
            chart-color-grey-dark: get-color("chart-color-grey-dark", "default");
        }
        legacy: {
            base-color: get-color("base-color", "legacy");
            base-color-transparent: get-color("base-color-transparent", "legacy");
            base-contrast-color: get-color("base-contrast-color", "legacy");
            base-contrast-two-color: get-color("base-contrast-two-color", "legacy");
            base-contrast-alt-color: get-color("base-contrast-alt-color", "legacy");
            primary-color: get-color("primary-color", "legacy");
            primary-contrast-color: get-color("primary-contrast-color", "legacy");
            secondary-color: get-color("secondary-color", "legacy");
            secondary-contrast-color: get-color("secondary-contrast-color", "legacy");
            secondary-contrast-soft-color: get-color("secondary-contrast-soft-color", "legacy");
            secondary-contrast-softer-color: get-color("secondary-contrast-softer-color", "legacy");
            secondary-contrast-softest-color: get-color("secondary-contrast-softest-color", "legacy");
            text-color: get-color("text-color", "legacy");
            text-soft-color: get-color("text-soft-color", "legacy");
            text-softest-color: get-color("text-softest-color", "legacy");
            border-color: get-color("border-color", "legacy");
            border-color-light: get-color("border-color-light", "legacy");
            logo-contrast-color: get-color("logo-contrast-color", "legacy");
            disabled-color: get-color("disabled-color", "legacy");
            overlay-color: get-color("overlay-color", "legacy");
            white-color: get-color("white-color", "legacy");
            black-color: get-color("black-color", "legacy");
            warning-color: get-color("warning-color", "legacy");
            placeholder-color: get-color("placeholder-color", "legacy");
            graph-color-yellow: get-color("graph-color-yellow", "legacy");
            graph-color-yellow-alt: get-color("graph-color-yellow-alt", "legacy");
            graph-color-green: get-color("graph-color-green", "legacy");
            graph-color-green-alt: get-color("graph-color-green-alt", "legacy");
            graph-color-grey: get-color("graph-color-grey", "legacy");
            graph-color-red: get-color("graph-color-red", "legacy");
            graph-color-red-alt: get-color("graph-color-red-alt", "legacy");
            chart-color-blue-light: get-color("chart-color-blue-light", "legacy");
            chart-color-blue-dark: get-color("chart-color-blue-dark", "legacy");
            chart-color-red-light: get-color("chart-color-red-light", "legacy");
            chart-color-red-dark: get-color("chart-color-red-dark", "legacy");
            chart-color-green-light: get-color("chart-color-green-light", "legacy");
            chart-color-green-dark: get-color("chart-color-green-dark", "legacy");
            chart-color-yellow-light: get-color("chart-color-yellow-light", "legacy");
            chart-color-yellow-dark: get-color("chart-color-yellow-dark", "legacy");
            chart-color-grey-light: get-color("chart-color-grey-light", "legacy");
            chart-color-grey-dark: get-color("chart-color-grey-dark", "legacy");
        }
        dark: {
            base-color: get-color("base-color", "dark");
            base-color-transparent: get-color("base-color-transparent", "dark");
            base-contrast-color: get-color("base-contrast-color", "dark");
            base-contrast-two-color: get-color("base-contrast-two-color", "dark");
            base-contrast-alt-color: get-color("base-contrast-alt-color", "dark");
            primary-color: get-color("primary-color", "dark");
            primary-contrast-color: get-color("primary-contrast-color", "dark");
            secondary-color: get-color("secondary-color", "dark");
            secondary-contrast-color: get-color("secondary-contrast-color", "dark");
            secondary-contrast-soft-color: get-color("secondary-contrast-soft-color", "dark");
            secondary-contrast-softer-color: get-color("secondary-contrast-softer-color", "dark");
            secondary-contrast-softest-color: get-color("secondary-contrast-softest-color", "dark");
            text-color: get-color("text-color", "dark");
            text-soft-color: get-color("text-soft-color", "dark");
            text-softest-color: get-color("text-softest-color", "dark");
            border-color: get-color("border-color", "dark");
            border-color-light: get-color("border-color-light", "dark");
            logo-contrast-color: get-color("logo-contrast-color", "dark");
            disabled-color: get-color("disabled-color", "dark");
            overlay-color: get-color("overlay-color", "dark");
            white-color: get-color("white-color", "dark");
            black-color: get-color("black-color", "dark");
            warning-color: get-color("warning-color", "dark");
            placeholder-color: get-color("placeholder-color", "dark");
            graph-color-yellow: get-color("graph-color-yellow", "dark");
            graph-color-yellow-alt: get-color("graph-color-yellow-alt", "dark");
            graph-color-green: get-color("graph-color-green", "dark");
            graph-color-green-alt: get-color("graph-color-green-alt", "dark");
            graph-color-grey: get-color("graph-color-grey", "dark");
            graph-color-red: get-color("graph-color-red", "dark");
            graph-color-red-alt: get-color("graph-color-red-alt", "dark");
            chart-color-blue-light: get-color("chart-color-blue-light", "dark");
            chart-color-blue-dark: get-color("chart-color-blue-dark", "dark");
            chart-color-red-light: get-color("chart-color-red-light", "dark");
            chart-color-red-dark: get-color("chart-color-red-dark", "dark");
            chart-color-green-light: get-color("chart-color-green-light", "dark");
            chart-color-green-dark: get-color("chart-color-green-dark", "dark");
            chart-color-yellow-light: get-color("chart-color-yellow-light", "dark");
            chart-color-yellow-dark: get-color("chart-color-yellow-dark", "dark");
            chart-color-grey-light: get-color("chart-color-grey-light", "dark");
            chart-color-grey-dark: get-color("chart-color-grey-dark", "dark");
        }
        henrik: {
            base-color: get-color("base-color", "henrik");
            base-color-transparent: get-color("base-color-transparent", "henrik");
            base-contrast-color: get-color("base-contrast-color", "henrik");
            base-contrast-two-color: get-color("base-contrast-two-color", "henrik");
            base-contrast-alt-color: get-color("base-contrast-alt-color", "henrik");
            primary-color: get-color("primary-color", "henrik");
            primary-contrast-color: get-color("primary-contrast-color", "henrik");
            secondary-color: get-color("secondary-color", "henrik");
            secondary-contrast-color: get-color("secondary-contrast-color", "henrik");
            secondary-contrast-soft-color: get-color("secondary-contrast-soft-color", "henrik");
            secondary-contrast-softer-color: get-color("secondary-contrast-softer-color", "henrik");
            secondary-contrast-softest-color: get-color("secondary-contrast-softest-color", "henrik");
            text-color: get-color("text-color", "henrik");
            text-soft-color: get-color("text-soft-color", "henrik");
            text-softest-color: get-color("text-softest-color", "henrik");
            border-color: get-color("border-color", "henrik");
            border-color-light: get-color("border-color-light", "henrik");
            logo-contrast-color: get-color("logo-contrast-color", "henrik");
            disabled-color: get-color("disabled-color", "henrik");
            overlay-color: get-color("overlay-color", "henrik");
            white-color: get-color("white-color", "henrik");
            black-color: get-color("black-color", "henrik");
            warning-color: get-color("warning-color", "henrik");
            placeholder-color: get-color("placeholder-color", "henrik");
            graph-color-yellow: get-color("graph-color-yellow", "henrik");
            graph-color-yellow-alt: get-color("graph-color-yellow-alt", "henrik");
            graph-color-green: get-color("graph-color-green", "henrik");
            graph-color-green-alt: get-color("graph-color-green-alt", "henrik");
            graph-color-grey: get-color("graph-color-grey", "henrik");
            graph-color-red: get-color("graph-color-red", "henrik");
            graph-color-red-alt: get-color("graph-color-red-alt", "henrik");
            chart-color-blue-light: get-color("chart-color-blue-light", "henrik");
            chart-color-blue-dark: get-color("chart-color-blue-dark", "henrik");
            chart-color-red-light: get-color("chart-color-red-light", "henrik");
            chart-color-red-dark: get-color("chart-color-red-dark", "henrik");
            chart-color-green-light: get-color("chart-color-green-light", "henrik");
            chart-color-green-dark: get-color("chart-color-green-dark", "henrik");
            chart-color-yellow-light: get-color("chart-color-yellow-light", "henrik");
            chart-color-yellow-dark: get-color("chart-color-yellow-dark", "henrik");
            chart-color-grey-light: get-color("chart-color-grey-light", "henrik");
            chart-color-grey-dark: get-color("chart-color-grey-dark", "henrik");
        }
    }
}


// VARIABLES
$page-header-bar-size: 48px;
$sidebar-width: 112px;
$base-color: var(--base-color);
$base-color-transparent: var(--base-color-transparent);
$base-contrast-color: var(--base-contrast-color);
$base-contrast-two-color: var(--base-contrast-two-color);
$base-contrast-alt-color: var(--base-contrast-alt-color);
$text-color: var(--text-color);
$text-soft-color: var(--text-soft-color);
$text-softest-color: var(--text-softest-color);
$border-color: var(--border-color);
$border-color-light: var(--border-color-light);
$primary-color: var(--primary-color);
$primary-contrast-color: var(--primary-contrast-color);
$secondary-color: var(--secondary-color);
$secondary-contrast-color: var(--secondary-contrast-color);
$secondary-contrast-soft-color: var(--secondary-contrast-soft-color);
$secondary-contrast-softer-color: var(--secondary-contrast-softer-color);
$secondary-contrast-softest-color: var(--secondary-contrast-softest-color);
$disabled-color: var(--disabled-color);
$overlay-color: var(--overlay-color);
$input-color: var(--input-color);
$logo-contrast-color: var(--logo-contrast-color);
$warning-color: var(--warning-color);
$placeholder-color: var(--placeholder-color);
$graph-color-yellow: var(--graph-color-yellow);
$graph-color-yellow-alt: var(--graph-color-yellow-alt);
$graph-color-green: var(--graph-color-green);
$graph-color-green-alt: var(--graph-color-green-alt);
$graph-color-grey: var(--graph-color-grey);
$graph-color-red: var(--graph-color-red);
$graph-color-red-alt: var(--graph-color-red-alt);
$chart-color-blue-light: var(--chart-color-blue-light);
$chart-color-blue-dark: var(--chart-color-blue-dark);
$chart-color-red-light: var(--chart-color-red-light);
$chart-color-red-dark: var(--chart-color-red-dark);
$chart-color-green-light: var(--chart-color-green-light);
$chart-color-green-dark: var(--chart-color-green-dark);
$chart-color-yellow-light: var(--chart-color-yellow-light);
$chart-color-yellow-dark: var(--chart-color-yellow-dark);
$chart-color-grey-light: var(--chart-color-grey-light);
$chart-color-grey-dark: var(--chart-color-grey-dark);



// MIXINS
@mixin page {
    padding: 32px 32px;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    // align-items: center;
}

@mixin card {
    border-radius: 4px;
    box-shadow: 1px 1px 6px 0px #00000025;
    background: $base-color;
    position: relative;
}

@mixin link {
    // border: none;
    text-decoration: underline;
    color: $primary-color !important;
}

@mixin smallScrollBar {
    &::-webkit-scrollbar {
        width: 12px !important;
        height: 12px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $base-color;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $primary-color; 
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $secondary-color;
        cursor: pointer !important;
    }

    ::-webkit-scrollbar-corner {
        background: $base-color;
    }
}

@mixin bigScrollBar {
    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: $secondary-color;
      }
       
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: $secondary-contrast-softest-color;
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: $primary-color; 
        cursor: pointer;
      }      

      ::-webkit-scrollbar-corner {
        background: $secondary-color;
       }
}