@import '../../../theme.scss';

.component {
    position: relative;
    display: flex;
    align-items: center;

    &_today {
        position: absolute;
        left: 128px;
        cursor: pointer;
        font-size: 11px;
        background-color: $base-contrast-color;
        padding: 2px 12px 3px 12px;
        border-radius: 12px;
        
        &:hover {
            color: $primary-color;
        }
    }

    input {
        flex: 1;
    }

    &>input::-webkit-calendar-picker-indicator {
        display: none;
        opacity: 0;
    }

    svg {
        // pointer-events: none;
        stroke: $text-color;
        width: 14px;
        position: absolute;
        right: 4px;
        top: calc(50% - 1px);
        transform: translate(-50%, -50%);
        &:hover {
            cursor: pointer;
            stroke: $primary-color;
        }
    }


}
