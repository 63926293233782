@import '../../../theme.scss';

.component {
    position: absolute;
    z-index: 9;
    border-radius: 8px;
    overflow: hidden;
    background-color: $base-contrast-alt-color;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.2);
    outline: solid 1px $border-color-light;
    &_list {
        position: relative;
        background-color: $base-contrast-alt-color;
        font-size: 12px;
        max-height: 278px;
        min-height: 64px;
        overflow-y: auto;
        @include smallScrollBar;
        &>div {
            position: relative;
            &:hover {
                color: $primary-color;
                cursor: pointer;
                background-color: $base-contrast-color;
            }
            padding: 4px 16px;
        }
    }
}

.active {
    color: $primary-contrast-color !important;
    background-color: $primary-color !important;
}

.removeNumberIndicator {
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type=number] {
        -moz-appearance: textfield;
    }
}