@import '../../theme.scss';

.component {
    border: 1px solid $border-color;
    height: max-content;
    width: 200px;
    background: $base-color;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 2;
    overflow: hidden;
    opacity: hidden;
    border-radius: 0 0 4px 0;
    box-shadow: 0px 0px 2px 0 #00000038;;
    top: 48px;
}

.button {
    cursor: pointer;
    font-size: 12px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    padding: 12px 16px;
    align-items: center;
    &:last-child {
        border-bottom: none;
    }

    &>* {
        pointer-events: none;
    }
    & svg {
        height: 12px;
        stroke: $text-soft-color;
        fill: $text-soft-color;
    }

    &:hover {
        background-color: $base-contrast-alt-color;
        & svg {
            stroke: $primary-color;
            fill: $primary-color;
        }
    }
}

.dropContainer {
    display: flex;
    flex-direction: column;
    background-color: $base-contrast-color;
    &>div {
        @extend .button;
        padding-left: 24px;
    }
}

.open_arrow {
    transform: rotate(90deg);
}

.radio_button_display {
    border-radius: 50%;
    aspect-ratio: 1;
    height: 10px;
    outline: 1px solid $text-soft-color;
    &__active {
        @extend .radio_button_display; 
        background-color: $text-soft-color;
    }
}
