@import '../../theme.scss';

.page {
    @include page;
    gap: 64px;
    &>div {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.section_wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &_inputs {
        display: flex;
        gap: 24px;
        &__fromdate, &__todate {
            width: max-content;
        }
        &__empty {
            padding-top: 18px;
        }
    }
}

.component {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    // grid-template-rows: 1fr 1fr 1fr; 
    gap: 32px; 

    &_main {
        @include card;
        grid-area: 1 / 1 / 2 / 3;
        background-color: $base-color;
        padding: 24px 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        &>span {
            font-size: 16px;
        }
        &>div {
            display: flex;
            justify-content: space-between;
            &>div {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 8px 16px;
                &>span {
                    &:nth-child(1) {
                        font-weight: bold;
                        font-size: 38px;
                    }
                    &:nth-child(2) {
                        // transform: translateX(4px)
                    }
                }
            }
        }
    }

    &_via {
        @include card;
        grid-area: 2 / 1 / 3 / 3;
        background-color: $base-color;
        padding: 24px 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        &>span {
            font-size: 16px;
        }
        &>div {
            display: flex;
            justify-content: center;
            gap: 82px;
            padding: 24px 64px;
            &>div {
                &:nth-child(1) {
                    display: flex;
                    justify-content: flex-end;
                    height: 200px;
          
                }
                &:nth-child(2) {
                    transform: translateY(-4px);
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 24px;
                }
            }
            // &>div {
            //     display: flex;
            //     flex-direction: column;
            //     align-items: center;
            //     padding: 8px;
            //     &>span {
            //         &:nth-child(1) {
            //             font-weight: bold;
            //             font-size: 38px;
            //         }
            //         &:nth-child(2) {
            //             // transform: translateX(4px)
            //         }
            //     }
            // }
        }
    }

    &_line {
        @include card;
        &>span {
            font-size: 16px;
        }
        background-color: $base-color;
        padding: 18px;
        grid-area: 3 / 1 / 3 / 5;
        height: 400px
    }
}

.container {
    @include card;
    background-color: $base-color;
    padding: 18px;
    // flex: 1;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: space-between;
    &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 3;
        display: flex;
        justify-content: space-around;
        &>div {
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: center;
            &>span {
                &:nth-child(1) {
                    font-weight: bold;
                    font-size: 32px;
                }
            }
        }
    }
    &:nth-child(2) {
        grid-area: 1 / 3 / 3 / 4;
    }

    &:nth-child(3) {
        grid-area: 2 / 1 / 3 / 3;
    }

    &:nth-child(4) {
        grid-area: 1 / 4 / 3 / 4;
    }

    &:nth-child(5) {
        grid-area: 3 / 1 / 3 / 5;
    }

    // &_pie {
    //     height: 70%;
    //     width: 70%;

    //     &_legend {
    //         display: flex;
    //         gap: 6px 16px;
    //         flex-wrap: wrap;
    //         justify-content: center;
    //     }
    // }
}


.custom_legend {
    font-size: 11px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    &>div {
        &:nth-child(1) {
            display: flex;
            justify-content: space-between;
            &>span {
                &:nth-child(1) {
                    font-weight: bold;
                    font-size: 16px;
                }
                &:nth-child(2) {
                    height: max-content;
                    align-self: flex-end;
                }
            }
        }
        &:nth-child(2) {
            width: 100%;
            height: 4px;
        }
    }
    // &>div {
    //     width: 100%;
    //     height: 4px;
    // }
}

.ChartSection {
    @include card;
    background-color: $base-color;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    &>span {
        font-size: 16px;
    }

    &_kursister {
        grid-area: 1 / 1 / 2 / 3;
        &>div {
            display: flex;
            justify-content: space-around;
            align-items: center;
            &>div {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 8px 16px;
    
                &>span {
                    &:nth-child(1) {
                        font-weight: bold;
                        font-size: 38px;
                    }
                    &:nth-child(2) {
                        // transform: translateX(4px)
                    }
                }
            }
        }
    }

    &_gender {
        grid-area: 1 / 3 / 3 / 4;
        &>div {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            &>div {
                display: flex;
                justify-content: center;
                &:nth-child(1) {
                    width: 200px;
                }
                &:nth-child(2) {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                }
            }
        }
    }

    &_type {
        grid-area: 1 / 4 / 3 / 4;
        &>div {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            &>div {
                &:nth-child(1) {
                    height: 200px;
                }
                &:nth-child(2) {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                }
            }
        }
    }

    &_via {
        grid-area: 2 / 1 / 3 / 3;
        &>div {
            display: flex;
            justify-content: center;
            gap: 52px;
            padding: 24px 64px;
            &>div {
                display: flex;
                &:nth-child(1) {
                    display: flex;
                    justify-content: flex-end;
                    height: 200px;
                    width: 200px;
          
                }
                &:nth-child(2) {
                    transform: translateY(-4px);
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 24px;
                }
            }
        }
    }

    &_overblik {
        grid-area: 3 / 1 / 3 / 5;
        &>div {
            height: 400px;
        }
    }
}

.alert {
    color: $warning-color;
    display: flex;
    gap: 12px;
    align-items: center;
    transform: translateY(9px);

    &>svg {
        transform: translateY(1px);
        fill: $warning-color;
        height: 20px;
    }
}

.loading {
    align-self: center;
}


.loading_chart {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
// .ok {
//     color: $graph-color-green;
// }

// .uafklaret {
//     color: $graph-color-yellow;
// }

// .frafald {
//     color: $graph-color-red;
// }