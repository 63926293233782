@import '../../../theme.scss';

.linkButton {
    background: $base-color;
    height: 30px;
    width: 42px;
    position: absolute;
    right: 0;
    z-index: 1;
    border: solid 1px $border-color;
    border-radius: 0 4px 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &_mail {
        height: 80% !important;
    }
    &>svg {
        pointer-events: none;
        stroke: $text-soft-color;
        height: 100%;
    }

    &:hover {
        &>svg {
            stroke: $primary-color;
        }
    }
}