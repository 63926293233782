@import '../../../theme.scss';

.component {
    gap: 32px;
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0 16px;
    &_loading {
        position: absolute;
        z-index: 5;
        height: calc(100% - 42px);
        width: calc(100% - 56px);
        flex: 1;
        background: $base-color-transparent;
        &>* {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
        }
    }
}

.content {
    display: flex;
    gap: 32px;
}

.img {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    &_placeholder {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        &_silhouette {
            fill: $base-color;
            position: absolute;
            height: 80%;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0%);
        }
    }
    &_preview {
        background-color: $base-contrast-color;
        height: 164px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: $base-contrast-color;
        position: relative;
        &_upload {
            border: 4px solid $base-color;
            height: 36px;
            aspect-ratio: 1;
            background: $base-contrast-color;
            position: absolute;
            right: 4px;
            bottom: 4px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                cursor: pointer;
                background: $base-color;
                &>svg {
                    stroke: $primary-color;
                }
            }
            &>svg {
                transform: translateY(-1px);
                height: 22px;
                aspect-ratio: 1;
                stroke-width: 1px !important;
                fill: none;
                stroke: $text-color;
            }
        }
    }
}

.split {
    width: 1px;
    background: $border-color;
}

.inputWrap {
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.footer {
    align-items: center;
    justify-content: space-between;
    display: flex;
    &_account_type {
        color: $text-soft-color;
        font-style: italic !important;
    }
}