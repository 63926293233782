@import '../../../theme.scss';

.custominput {
    flex: 1;
    &>* {
        pointer-events: none;
        user-select: none;
    }
    font-family: main;
    padding: 8px 8px;
    border: none;
    border-radius: 4px;
    border: $border-color 1px solid;
    font-size: 12px;
    letter-spacing: 2px;
    background-color: $base-contrast-alt-color;
    color: $text-color !important;

    &:focus {
        color: $text-color !important;
        outline: none;
        border-color: $primary-color !important;
    }

    &:disabled {
        background-color: $disabled-color !important;
        opacity: 0.9;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
}

.wrapper {
    display: flex;
    position: relative;
    &>select {
        width: 100%;
    }
    &_disabled {
        display: flex;
        position: relative;
        &>select {
            width: 100%;
        }
        &>.custominput {
            background-color: $disabled-color !important;
            opacity: 0.9;
            &>span {
                cursor: inherit;
                pointer-events: none;
            }
        }
    }
}

.wrapper::after {
    content: "▼";
    pointer-events: none;
    font-size: 8px;
    top: 11px;
    right: 11px;
    position: absolute;

  }

.custom_options {
    position: absolute;
    z-index: 9;
    border-radius: 8px;
    overflow: hidden;
    background-color: $base-contrast-alt-color;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.2);
    outline: solid 1px $border-color-light;
    &_search {
        width: 100%;
        display: flex;
        &>input {
            pointer-events: none;
            cursor: default;
            font-size: 12px !important;
            width: auto;
            flex: 1;
            // border-color: $primary-color;
            border-color: $border-color-light !important;
        }
    }
    &_list {
        position: relative;
        background-color: $base-contrast-alt-color;
        font-size: 12px;
        max-height: 278px;
        min-height: 64px;
        overflow-y: auto;
        @include smallScrollBar;
        &>div {
            position: relative;
            &:hover {
                color: $primary-color;
                cursor: pointer;
                background-color: $base-contrast-color;
            }
            padding: 4px 16px;
        }
    }
}

.active {
    color: $primary-contrast-color !important;
    background-color: $primary-color !important;
}

.searchWrap {
    padding: 8px 12px;
    border-bottom: 1px solid $border-color-light;
}

.error {
    border-bottom: 1px solid $primary-color !important;
}

.custom_option {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
}

.placeholder {
    padding: 0 5px;
    color: $placeholder-color;
}