@import '../../theme.scss';

.container {
    height: 50vh;
    width: max-content;
    max-width: 320px;
    min-width: 192px;
    position: fixed;
    z-index: 99;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 8px;
    padding: 8px;
    pointer-events: none
}

.card {
    @include card;
    background-color: $base-color-transparent;
    pointer-events: auto;
    animation-name: fadeIn; 
    padding: 8px 12px;
    display: flex;
    gap: 4px;
    flex-direction: column;
    &:hover {
        cursor: pointer;
        outline: 1px solid $primary-color;
    }
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }