@import '../../theme.scss';

.component {
    @include page;
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
}

.content {
    transform: translateY(-40px);
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    &_text {
        font-size: 32px;
        font-weight: bold;
        &_alt {
            color: $text-soft-color;
        }
    }
}