@import '../../theme.scss';

.component {
    font-size: 12px;
    text-align: left;
    border-collapse: collapse;


    & th {
        padding: 16px 12px !important;
        font-weight: bold;
        white-space: nowrap;
        max-width: 400px;
        color: $text-color;
        background: $base-color;
        position: sticky;
        top: 0;
        transform: translateY(-1px);
        z-index: 1;
        &:hover {
            color: $primary-color;
        }
        & span {
            transform: translateY(3px);
        }
    }

    & tr {
        &:nth-child(1) {
            background: $base-color;
            &:hover {
                background: initial;
            }
        }
        &:nth-child(odd) {
            background-color: $base-contrast-two-color;
        }
        border-bottom: 1px solid $border-color;
        background-color: $base-contrast-color;
        &:hover {
            transition: background-color 0.125s ease-out;
            background-color: $base-contrast-alt-color;
            cursor: pointer;
            &>td {
                color: $primary-color;
                & span {
                    border-color: $primary-color !important;
                }
            }
        }
    }    

    & td {
        color: $text-color;
        padding: 8px 12px;
        & label {
            padding: 0 0 2px 0;
        }
    }
}

.message_container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 32px 0 32px 0;
    align-items: center;
}

.buttonColumn {
    padding: 0 !important;
    &>div {
        display: flex;
        justify-content: center;
        &>svg {
            height: 18px;
        }
    }
}

.loading {
    height: 100%;
}

.arrow {
    fill: $primary-color;
    height: 8px;
    &_down {
        @extend .arrow;
        transform: translate(6px, 1px);
    }

    &_up {
        @extend .arrow;
        transform: translate(6px, -1px) rotate(180deg);
    }
}

.selected_row {
    background-color: $primary-color !important;
    &>td {
        color: $primary-contrast-color !important;
    }
    &:hover {
        background-color: $primary-color !important;
        &>td {
            color: $primary-contrast-color !important;
        }
    }
}

.link {
    @include link
}

.custom_cell_right {
    display: flex;
    justify-content: flex-end;
}