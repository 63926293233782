@import '../../../../theme.scss';

.component {
    display: flex;
    justify-content: space-between;
    padding: 0 0 4px 0;
    &>div {
        gap: 16px;
        display: flex;
        align-items: center; 
    }
}
.dateBarFilter {
    display: flex;
    padding: 2px 2px 12px 2px;
    flex-wrap: wrap;
    gap: 8px;
    border-bottom: 1px solid $border-color;
    margin-bottom: 12px;
    &_reset_button {
        @extend .reset_button;
        align-self: center;
    }
    &_preset {
        background: $base-contrast-alt-color;
        border-radius: 8px;
        white-space: nowrap;
        color: $text-color;
        border: 1px solid $border-color;
        padding: 4px 8px;
        font-family: main;
        font-size: 12px;
        padding: 8px 8px;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &_active {
            @extend .dateBarFilter_preset;
            color: $primary-contrast-color !important;
            background: $primary-color !important;
            border-color: $primary-color;
        }
        &:hover {
            border-color: $primary-color;
            color: $primary-color;
            cursor: pointer;
        }
    }
    &_selects {
        display: flex;
        &>div {
            width: 112px;
            &:nth-child(1) {
                &>div>div {
                    border-radius: 8px 0 0 8px;
                }
            }
            &:nth-child(2) {
                &>div>div {
                    border-radius: 0 8px 8px 0;
                }
            }
        }
    }
}
.filter_wrap {
    gap: 16px;
    display: flex;
    flex-direction: column;
    padding: 16px;

    &>div {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &_selects {
        display: flex;
    }
    &_title {
        padding-bottom: 4px;
        width: 100%;
        border-bottom: $border-color 1px solid;
    }

    &_content {
        display: grid;
        padding: 4px;
        gap: 8px;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

    }
}

.reset_button {
    color: $text-soft-color;
    text-decoration: underline;
    white-space: nowrap;
    font-size: 13px;
    cursor: pointer;
    &:hover {
        color: $primary-color;
    }   
}