@import '../../theme';

.component {
    border-right: 1px solid $secondary-color;
    background: $secondary-color;
    width: $sidebar-width;
    min-width: $sidebar-width;
}

.content {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
}

.button {
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 72px;
    cursor: pointer;
    width: 100%;
    color: $secondary-contrast-softer-color;
    text-decoration: none;
    border-bottom: 1px solid $secondary-contrast-softest-color;
    padding: 8px 0;
    transition: background-color 0.2s ease-out, color 0.1s ease-out;
    font-size: 10px;

    & svg {
        transition: stroke 0.1s ease-out;
        stroke: $secondary-contrast-softer-color;
        fill: none;
        width: 32px;
        height: auto;
        pointer-events: none
    }

    &__active {
        @extend .button;
        color: $secondary-contrast-color !important;

        & svg {
            stroke: $primary-color !important;
        }
    }

    &:hover {
        color: $secondary-contrast-soft-color;
        background-color: $secondary-contrast-softest-color;
        & svg {
            stroke: $secondary-contrast-soft-color;
        } 
    }
}