@import '../../../../theme.scss';

.component {
    height: 100%;
    gap: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    overflow: hidden;
    flex: 1;
    &_split {
        width: 1px;
        background: $border-color;
    }
}

.section {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
}

.actions {
    display: flex;
    gap: 8px;
}

.table_section {
    flex: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.table_heading {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;
}

.loading_overlay {
    position: absolute;
    background: $base-color;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    opacity: 0.75;
    &>svg {
        position: absolute;
        transform: translate(-50%,- 50%);
        left: 50%;
        top: 50%;
    }
}

.delete_card {
    padding: 24px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    text-align: center;
    gap: 24px;
    & span {
        font-weight: bold;
    }

    &_split {
        height: 1px;
        width: 100%;
        background-color: $border-color;
    }

    &_warning {
        flex-direction: column;
    }

    &>div {
        display: flex;
        width: 100%;
        gap: 16px;
        justify-content: center;
    }
}

.scrollContainer {
    @include smallScrollBar;
    overflow: auto;
}

.warning_wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 12px;
}

.goTo_link {
    @include link;
    font-weight: bold;
    cursor: pointer;
}

.move {
    &_link {
        @include link;
        font-weight: bold;
        cursor: pointer;
    }
    &_arrow {
        fill: $text-soft-color;
        height: 24px;
        transform: translate(1px, -5px) rotate(-90deg);
        
    }
    &_container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 16px 8px;
    }
    &_input_container {
        display: flex;
        gap: 16px;
        align-items: flex-end;
        &>div {
            min-width: 224px;
        }
    }
    &_button_container {
        display: flex;
        flex: 1;
        gap: 8px;
    }
}


.warning {
    color: $warning-color;
}

.bold {
    font-weight: bold;
}