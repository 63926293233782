.editBase {
    background-color: rgba(185, 221, 255, 0.219);
    outline: 1px dashed rgb(66, 164, 255);
    cursor: pointer;
    &:hover {
        background-color: rgba(185, 221, 255, 0.419);
    }
    &_selected {
        background-color: rgba(255, 255, 255, 0.712);
        outline: 2px solid rgb(66, 164, 255);
        outline-offset: 1px;
        &:hover {
            background-color: rgb(255, 255, 255, 0.712);
            outline: 2px solid rgb(66, 164, 255);
            outline-offset: 1px;
        }
    }
}

.displaycomponent {
    color: black !important;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    background-color: #333333;
    height: max-content;
    width: 100%;
    min-height: calc(100% - 48px);
    justify-content: center;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;
}

.addpage {
    background: #00d569;
    stroke: white;
    height: 14px;
    width: 14px;
    padding: 8px;
    border: 2px white solid;
    box-shadow: 1px 1px 8px 0 black;
    cursor: pointer;
    border-radius: 50px;
    opacity: 0.85;
    &>svg {
        transform: rotate(45deg);
    }
    &:hover {
        opacity: 1;
    }
}

.removePage {
    background: red;
    stroke: white;
    height: 14px;
    width: 14px;
    padding: 8px;
    border: 2px white solid;
    box-shadow: 1px 1px 8px 0 black;
    position: absolute;
    right: -12px;
    top: -12px;
    cursor: pointer;
    border-radius: 50px;
    opacity: 0.85;
    &:hover {
        opacity: 1;
    }
}

.text_hover {
    background: rgb(255, 248, 186) !important;
}

.text_focus {
    background: rgb(255, 234, 49) !important;
}