@import '../../../../theme.scss';

@mixin pdfScrollBar {
    &::-webkit-scrollbar {
        width: 15px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #2c2c2c;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #777777; 
        border-radius: 40px;
        border: 3px solid #2c2c2c;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #9f9f9f; 
        cursor: pointer !important;
    }
}

@font-face {
    font-family: CArial;
    src: url(./assets/fonts/ARIALLGT.TTF) format('truetype');
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: CArial;
    src: url(./assets/fonts/ARIAL.TTF) format('truetype');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: CArial;
    src: url(./assets/fonts/ARIALBD.TTF) format('truetype');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: CArial;
    src: url(./assets/fonts/ARIBLK.TTF) format('truetype');
    font-style: normal;
    font-weight: 900;
}

.component {
    display: flex;
    flex: 1;
    height: 100%;
}

.list {
    flex: 0.15;
    display: flex;
    flex-direction: column;
    background-color: #3b3b3b;
    overflow: auto;
    @include pdfScrollBar;
    &_template {
        font-weight: bold !important;
    }
    &>div {
        position: relative;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        padding: 8px 8px;
        border-radius: 4px;
        cursor: pointer;
        &>span {
            font-weight: lighter;
            color: white;
        }
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
}

.selected {
    background-color: $primary-color;
    &:hover {
        background-color: $primary-color !important; 
    }
}

.link {
    @include link;
}

.view {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    overflow: auto;
    &_tabs {
        
        background-color: #3b3b3b;
        display: flex;
        gap: 16px;
        &_marginFix {
            @extend .view_tabs;
            width: calc(100% - 1px);
        }
        &>div {
            display: inline-flex;
            &>span {
                color: white;
                padding: 8px 16px;
                cursor: pointer;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                }
                border-radius: 4px;
            }
        }
        &_selected {
            background-color: $primary-color !important;
        }
    }
    &_display {
        flex: 1;
        display: flex;
        position: relative;
        overflow: auto;
        @include pdfScrollBar;
        & p, span {
            font-family: CArial !important;
            letter-spacing: 0px;
        }
        & iframe {
            border: none;
            & body {
                @include pdfScrollBar;
            }
        }
    }
}

.options {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
}

.pdfViewer {
    width: 100%;
    height: 100%;
}

.bottemBar {
    display: flex;
    justify-content: space-between;
    background: #2c2c2c;
    &>div {
        flex: 1;
        text-align: center;
        color: white;
        padding: 8px 0;
        cursor: pointer;
        &:hover {
            background: $primary-color;
        }
        
    }
}

.changeIndicator {
    background: yellow;
    position: absolute;
    border-radius: 40px;
    color: black !important;
    transform: translate(-50%, -50%);
    top: 50%;
    right: 0;
    font-weight: bolder !important;
    padding: 2px 4px 4px 5px;
}

.saveTemplateConfirm {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    min-width: 600px;
    flex-direction: column;
    &_actions {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: center;
    }
}

.templateSelect {
    overflow: hidden;
    color: white !important;
    &>div {
        &>div {
            overflow: hidden;
            border-color: transparent;
            padding-right: 24px;
            background-color: #3b3b3b;
            &:hover {
                border-color: transparent;
                background-color: $primary-color;
                cursor: pointer;
            }
        }
    }
    & span {
        color: white !important;
        white-space: nowrap;
        overflow: hidden;
    }
    &:focus {
        border-color: transparent;
    }
}

.delete_card {
    padding: 24px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 320px;
    text-align: center;
    gap: 24px;
    & span {
        font-weight: bold;
    }

    &>div {
        display: flex;
        width: 100%;
        gap: 16px;
        justify-content: center;
    }
}

.pathDisplay {
    font-size: 12px;
    color: $text-soft-color;
    align-self: flex-start;
}

.mail {
    font-size: 12px !important;
    padding: 24px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 320px;
    text-align: center;
    gap: 24px;

    &>div {
        display: flex;
        width: 100%;
        gap: 16px;
        justify-content: center;
    }

    &_preview {
        text-align: left;
        background-color: $base-contrast-color;
        padding: 12px;
        &>html {
            padding: 0;
        }
        &_content {
            font-size: 11px !important;
            line-height: 3 !important;
            width: 1000px;
            padding: 0 !important;
            border: 0 !important;
            outline: 0 !important;
            border-radius: 0 !important;
            outline: inset 1px solid black;
            color: black !important;
        }

        & p {
            color: black;
        }
    }
}

.loading_overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    background: $base-color-transparent;
    &_content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
        text-align: center;
        align-items: center;
        color: $text-color !important;
        position: relative;
        transform: translateY(-24px);
        &>p {
            font-size: 16px;
        }
    }
}