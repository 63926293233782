@import '../../../../theme.scss';

.component {
    height: 100%;
    gap: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    overflow: hidden;
    flex: 1;
    &_split {
        width: 1px;
        background: $border-color;
    }
}

.Filter_split {
    width: 1px;
    height: 100%;
    margin-right: 8px;
    background: $border-color;
}

.cell_status {
    display: flex;
    gap: 8px;
    align-items: center;

    & svg {
        transform: translateY(1px);
    }
}

.status_filter {
    font-size: 14px;
    display: flex;
    gap: 16px;
    
}

.heading {
    display: flex;
    justify-content: space-between;
    &_actions {
        display: flex;
        align-items: center;
        gap: 16px;
    }
}

.section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    &_small {
        @extend .section;
        min-width: 240px;
        position: relative;
        &_footer {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }
    &_big {
        @extend .section;
        overflow: hidden;
        gap: 8px;
        flex: 2.5;
    }
}

.tableContainer {
    flex: 1;
    overflow: auto;
    margin-top: 2px;
    @include smallScrollBar;
    & td {
        white-space: nowrap !important;
    }
}

.select_options {
    background-color: $secondary-color;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    bottom: 0;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
    &>div {
        gap: 16px;
        display: flex;
    }
}

.delete_card {
    padding: 24px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    text-align: center;
    gap: 24px;
    & span {
        font-weight: bold;
    }

    &>div {
        display: flex;
        width: 100%;
        gap: 16px;
        justify-content: center;
    }
}

.select_all_status {
    cursor: pointer;
    // @include link
    color: $text-soft-color;
    text-decoration: underline;
    font-size: 12px;
    &:hover {
        color: $primary-color;
    }
}

.Kursist_select {
    overflow: hidden;
}

.mail_card {
    padding: 24px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    text-align: center;
    gap: 24px;
    &>a {
        @include link;
    }

    &>div {
        display: flex;
        width: 100%;
        gap: 16px;
        justify-content: center;
    } 
}

.colorSelect {
    display: flex;
    gap: 8px;
    align-items: center;
}


.edit_card {
    padding: 24px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    min-width: 800px;
    text-align: center;
    gap: 24px;
    &_inputs {
        display: flex;
        flex-direction: column;
        gap: 16px;
        &>div {
            text-align: left;
            &>label {

            }
            justify-content: flex-start;
        }
    }
    &_footer {
        display: flex;
        justify-content: center;
        gap: 16px;
    }

}

.loading_overlay {
    position: absolute;
    background: $base-color;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    opacity: 0.75;
    &>svg {
        position: absolute;
        transform: translate(-50%,- 50%);
        left: 50%;
        top: 50%;
    }
}

.addButton {
    margin: 8px;
    width: 160px;
}

.button_loading {
    color: $text-soft-color !important;
    border-color: $base-color !important;
    background-color: $disabled-color !important;
    pointer-events: none !important;
}

.editPDF {
    display: flex;
    gap: 24px;
    &_props {
        display: flex;
        flex-direction: column;
        gap: 16px;
        &_title {
            padding: 0 0 4px 0;
            text-align: left;
            font-size: 24px;
            font-weight: bold;
            border-bottom: solid 1px $primary-color;
        }
        &_button {
            text-align: left;
            padding: 6px;
            &:hover {
                background: $base-contrast-color;
                color: $primary-color;
                cursor: pointer;
            }
            &_container {
                display: flex;
                flex-direction: column;
            }
        }
        flex: 0.5;
        max-width: 300px;
    }
    &_inputs {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: flex-start;
        text-align: start;
    }
}