@import '../../../../theme.scss';

.component {
    height: 100%;
    gap: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex: 1;
    &_split {
        width: 1px;
        background: $border-color;
    }
}

.section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    flex: 1;
    &_scrollable {
        flex: 1;
        @include smallScrollBar;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        &_container {
            padding-bottom: 24px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;
            position: absolute;
            flex: 1;
            &>div {
                width: calc(100% - 16px);
            }
        }
    }
}

.split_inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 16px;
    &>div {
        overflow: hidden;
    }
}

.heading {
    display: flex;
    justify-content: space-between;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
    &>div {
        gap: 16px;
        display: flex;
    }
}

.hold {
    background: $base-contrast-color;
    position: relative;
    overflow-y: auto;
    @include smallScrollBar;
    flex: 1;

    &_load {
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        opacity: 0.5;
    }
    &_list {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px;
        width: calc(100% - 16px);
    }
    &_card {
        @include card;
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 8px;
        background: $base-color;
        cursor: pointer;

        &_folder {
            position: absolute;
            right: 0;
            bottom: 0;
            &>svg {
                height: 28px;
                fill: none;
                stroke: $text-soft-color;
                padding: 4px 8px;
                &>* {
                    pointer-events: none;
                }
                &:hover {
                    stroke: $primary-color;
                }
            }
        }
        &:hover {
            outline: 1px solid $primary-color;
        }
        &_prop {
            font-size: 14px;
            &>span {
                font-weight: bold;
            }
        }

        &_title {
            font-weight: bold;
        }

    }
}

.Hold_select {
    overflow: hidden;
}

.delete_card {
    padding: 24px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    text-align: center;
    gap: 24px;
    & span {
        font-weight: bold;
    }

    &>div {
        display: flex;
        width: 100%;
        gap: 16px;
        justify-content: center;
    }
}

.loading_overlay {
    position: absolute;
    background: $base-color;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    opacity: 0.75;
    &>svg {
        position: absolute;
        transform: translate(-50%,- 50%);
        left: 50%;
        top: 50%;
    }
}

.addButton {
    margin: 8px;
    width: 160px;
}

.button_loading {
    color: $text-soft-color !important;
    border-color: $base-color !important;
    background-color: $disabled-color !important;
    pointer-events: none !important;
}