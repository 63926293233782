@import '../../../theme.scss';

.Loader {
    transform: scale(0.8);
    transform-origin: 50px 50px;
}

.outline {
    stroke: $primary-color;
}

.content {
    fill: $primary-color;
}