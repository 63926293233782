@import '../../theme.scss';

.page {
    @include page;
    gap: 24px;
    &_inputs {
        display: flex;
        gap: 24px;
        &__fromdate, &__todate {
            width: max-content;
        }
        &__empty {
            padding-top: 18px;
        }
    }
}

.alert {
    color: $warning-color;
    display: flex;
    gap: 12px;
    align-items: center;
    transform: translateY(9px);

    &>svg {
        transform: translateY(1px);
        fill: $warning-color;
        height: 20px;
    }
}

.card {
    @include card;
    background: $base-color;
    padding: 12px;
}

.loader {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    &>svg {
        height: 64px;
        aspect-ratio: 1;
    }
}

.quick_sums {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-auto-rows: 1fr;

    // &__green {
    //     color: green;
    // }

    // &__yellow {
    //     color: $warning-color;
    // }

    // &>div {
    //     @include card;
    //     display: flex;
    //     flex-direction: column;
    //     text-align: center;
    //     padding: 16px 8px 8px 8px;
    //     &>span {
    //         font-weight: bold;
    //         font-size: 18px;
    //     }

    //     &>div {
    //         flex-grow: 1;
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: center;
    //         &>span {
    //             font-size: 14px;
    //         }
    //     }
    // }
}

.graph {
    position: relative;
    @include card;
    height: max-content;
    padding: 8px 16px 16px 16px;
}

.hold_coloumn {
    width: 100%;
    &>div {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
        align-items: center;
        &>svg {
            min-height: 18px;
            max-height: 18px;
            min-width: 18px;
            cursor: pointer;
            stroke: $text-color;
            &:hover {
                stroke: $primary-color;
            }
        }
    }
}

.sum_row {
    background: $base-color !important;
    box-shadow: 0 -2px 0 0 $primary-color;
    font-weight: bold !important;
}

.table {
    border-collapse: collapse;
    color: $text-color;
    text-align: left;
    &_textwrap {
        gap: 8px;
        display: flex;
        justify-content: space-between;
    }
    & th {
        padding: 8px;
        width: 1%;
        white-space: nowrap;
        background: $base-color !important;
        border: $border-color 1px solid;
        position: sticky;
        transform: translateY(-1px);
        top: 0;
    }
    & td {
        padding: 8px 12px;
        border: $border-color 1px solid;
        &>div {
            display: flex;
            flex-direction: column;
            gap: 4px;
            &>span {
                white-space: nowrap;
            }
        }
    }

    & tr:nth-child(even) {
        background: $base-contrast-color;
    }
    & tr:nth-child(odd) {
        background: $base-contrast-two-color;
    }
}

.heading {
    margin-top: 32px;
}

.statistik_header {
    margin-top: 32px;
    display: flex;
    gap: 8px;
    align-items: center;
    &>h2 {
        width: max-content;
        height: max-content;
        white-space: nowrap;
    }
    &>div {
        aspect-ratio: 1;
        height: 26px;
        &>svg {
            height: 100%;
            width: 100%;
            fill: $text-color;
            &:hover {
                cursor: pointer;
                fill: $primary-color;
            }
        }
    }
}