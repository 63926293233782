@import '../../../../theme.scss';

.cell_jsx {
    display: flex;
    gap: 8px;
    align-items: center;

    & svg {
        transform: translateY(1px);
    }
}

.colorSelect {
    display: flex;
    gap: 8px;
    align-items: center;
}