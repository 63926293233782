@import '../../theme.scss';

.textWrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 12px;
    padding-top: 8px;
}

.component {
    @include card;
    display: flex;
    height: 100%;
    width: 100%;
    &_container {
        flex: 1;
        padding: 16px 8px 8px 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        &_text {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
        }
    }
    &_graph_wrap {
        position: relative;
        display: flex;
        justify-content: center;
        &_canvas {
            width: 94px !important;
            height: 94px !important;
        }
        &_center {
            display: flex;
            flex-direction: column;
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            text-align: center;
        }
    }
    &_name {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
    }
    &_value {
        font-size: 32px;
    }
    &_percent {
        font-size: 12px;
        transform: translate(2px, -2px);
    }
}

@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
}