@import '../../theme.scss';

.component {
    border: 1px solid $border-color;
    height: max-content;
    width: 200px;
    background: $base-color;
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    z-index: 2;
    overflow: hidden;
    opacity: hidden;
    border-radius: 0 0 4px 0;
    box-shadow: 0px 0px 2px 0 #00000038;;
    top: 48px;
}


.button {
    cursor: pointer;
    font-size: 12px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    padding: 12px 16px;
    align-items: center;
    &:last-child {
        border-bottom: none;
    }

    &>* {
        pointer-events: none;
    }

    & svg {
        height: 12px;
        stroke: $text-soft-color;
        fill: $text-soft-color;
    }

    &_profile_icon {
        fill: none !important;
        transform: scale(1.5);
        stroke-width: 1.5px;
    }

    &_logout_icon {
        transform: translateX(-2px) scale(1.3);
        stroke: none !important
    }

    &:hover {
        background-color: $base-contrast-alt-color;
        & svg {
            stroke: $primary-color;
            fill: $primary-color;
        }
    }
}