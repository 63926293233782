@import '../../../theme.scss';

.component {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    &_size_mid {
        height: 75vh;
        width: 65vw;
    }
    &_size_small {
        height: 45vh;
        width: 33vw;
    }
    &_size_auto {
        height: max-content;
        width: max-content;
    }
    
}

.navigation {
    z-index: 3;
    background: $base-color;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid $border-color;

}

.tabs {
    display: flex;
    font-size: 12px;

    &__button {
        padding: 0 8px 8px 8px;
        
        &:hover {
            color: $primary-color;
            cursor: pointer;
        }

        &_active {
            @extend .tabs__button;
            font-weight: bold;
            box-shadow: 0 3px 0 -1px $primary-color;
        }
    }
}

.content {
    flex: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    padding: 12px 4px 0px 4px;
    &>div {
        flex: 1;
        max-width: 100%;
    }
    &_scrollable {
        @extend .content;
        @include smallScrollBar;
        overflow: auto;
    }
}
