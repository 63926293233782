@import './theme.scss';

@font-face {
  font-family: main;
  src: local('Arial, sans-serif'), url(./assets/fonts/font.ttf);
  font-display: swap;
}

html {
  height: max-content;
}

body {
  height: max-content;
  font-size: 14px;
  font-family: 'main', Arial, sans-serif ;
  letter-spacing: 1.6px;
  background-color: $secondary-color;
  color: $text-color;
  margin: 0;
  overflow-y: scroll;
  overflow-x: auto;
}

button, input, select, textarea, a {
  letter-spacing: 1.6px;
}

p, h1, h2, h3, h4 {
  margin: 0
}

:root {
  @include defaultColors;
}

#root {
  height: max-content;
  height: 100%
}

.page {
  @include page;
}

.inactive_load {
  opacity: 0.4;
  pointer-events: none;
  &>* {
    color: $primary-color !important;
  }
}

@include bigScrollBar;