@import '../../../../theme.scss';

.delete_card {
    padding: 24px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    text-align: center;
    gap: 24px;
    & span {
        font-weight: bold;
    }

    &>div {
        display: flex;
        width: 100%;
        gap: 16px;
        justify-content: center;
    }
}


.Trash {
    height: 24px;
    fill: none;
    opacity: 0.7;

    &:hover {
        opacity: 1;
        cursor: pointer;
        &>svg {
            stroke: $primary-color;
        }
    }
    &>svg {
        height: 100%;
        stroke: $text-color;
    }
}

.blur {
    opacity: 0.4;
}