@import '../../../theme.scss';

.component {
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 15px;
    cursor: pointer;
    margin: 0;
    height: 16px;
}

input[type=checkbox] {
    position: absolute;
    opacity: 0;
    height: 16px;
    width: 0;
    &:disabled {
        position: absolute;
        opacity: 0;
        height: 16px;
        width: 0;
    }
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: $base-contrast-alt-color;
    border-radius: 4px !important;
    border: 1px solid $border-color;
}

.component:hover input ~ .checkmark {
    border-color: $primary-color;
}

.component input:checked ~ .checkmark {
    background-color: $secondary-color;
    border-color: $secondary-color;
}

.component input:disabled ~ .checkmark {
    background-color: $disabled-color;
    border-color: $border-color;
    &:after {
        border-color: $text-color;
    }

    &:hover {
        border-color: none;
    }
}



.component input:focus ~ .checkmark {
    border-color: $primary-color;
}



.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.component input:checked ~ .checkmark:after {
    display: block;
}

.component .checkmark:after {
    left: 5px;
    bottom: 5px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox {
    flex-direction: row-reverse;
    gap: 8px;
    align-items: center;
    width: max-content;
    &>label {
        min-width: 16px;
        padding: 0;
        // display: flex;
        &:nth-child(1) {
            padding-top: 2px;
            display: flex;
            align-items: center;
        }
    }
    
}
