@import '../../../../theme.scss';

.component {
    height: 100%;
    gap: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    overflow: hidden;
    flex: 1;
    &_split {
        width: 1px;
        background: $border-color;
    }
}

.table_section {
    flex: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.scrollContainer {
    @include smallScrollBar;
    overflow: auto;
}

.section {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
}

.errorMessage {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
    &>svg {
        height: 42px;
        fill: $text-color;
    }
}

.table_heading {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
}
