@import '../../theme.scss';

.page {
    @include page;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0
}

.card {
    @include card;
    background: $base-contrast-color;
    padding: 32px 64px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
}

.logo {
    height: 64px;
}