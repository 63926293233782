@import '../../theme.scss';

.base {
    padding: 9px 32px 9px 32px;
    font-size: 12px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    margin: 2px;

    &:hover {
        transition: background-color 0.1s ease-in, border-color 0.1s ease-in, color 0.1s ease-in;
    }

    &_rounded {
        border-radius: 24px;
    }

    &_style {
        &_outlined {
            border: 1px solid;
            background: none;
            color: $text-color;
            border-color: $border-color;
            background-color: $base-contrast-alt-color;
            &:hover {
                color: $primary-color;
                border-color: $primary-color;
            }
            &--active {
                @extend .base_style_outlined;
                border-color: $text-color;
            }
            &_main {
                @extend .base_style_outlined;
                color: $primary-color;
                border-color: $primary-color;
                &:hover {
                    background: $primary-color;
                    color: $primary-contrast-color;
                }
            }
        }

        &_default {
            border: 1px solid $secondary-color;
            color: $secondary-contrast-color;
            background-color: $secondary-color;
            &:hover {
                background-color: $primary-color;
                border-color: $primary-color;
            }

            &:focus {
                border: solid 1px $primary-color;
                outline: solid 2px $primary-color;
            }

            &_main {
                @extend .base_style_default;
                background-color: $primary-color;
                &:hover {
                    background-color: $secondary-color;
                }
            }
        }
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.small {
    padding: 4px 12px;
}