@import '../../../../../theme.scss';

.deleteIcon {
    height: 20px;
    stroke: $text-color;
    fill: none;
    &:hover {
        stroke: $primary-color;
        cursor: pointer;
    }
}

.optionsWrap {
    overflow: auto;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.titleInput {
    font-size: 14px !important;
    font-weight: bold;
}

.listItem {
    display: flex;
    background: $base-contrast-color;
    padding: 8px;
    border-radius: 8px;
    gap: 8px;
    &_content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &_buttonWrap {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    &_deleteIcon {
        @extend .deleteIcon;
    }
    &_arrow {
        width: 14px;
        fill: $text-soft-color;
        &:hover {
            cursor: pointer;
            fill: $primary-color;
        }
    }
    &_arrow_up {
        transform: rotate(-180deg) translateX(-3px);
        @extend .listItem_arrow;
    }
    &_arrow_down {
        transform: translateX(3px);
        @extend .listItem_arrow;
    }
    &_copy {
        width: 17px;
        fill: $text-color;
        transform: translate(2px, 2px);
        &:hover {
            cursor: pointer;
            fill: $primary-color;
        }
    }
    &_inset {
        color: $primary-contrast-color;
        background: #42d176;
        border-radius: 24px;
        padding: 1px 12px 2px 12px;
        align-self: center;
        &:hover {
            cursor: pointer;
            background-color: #2a9f54;
        }
    
    }
}

.listItem_sub {
    display: flex;
    background: $base-color;
    padding: 8px;
    border-radius: 8px;
    gap: 8px;
    &_content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &>svg {
        @extend .deleteIcon;
    }
    &_arrow {
        width: 14px;
        fill: $text-softest-color;
        &:hover {
            cursor: pointer;
            fill: $primary-color;
        }
    }
    &_arrow_up {
        transform: rotate(-180deg) translateX(-3px);
        @extend .listItem_sub_arrow;
    }
    &_arrow_down {
        transform: translateX(3px);
        @extend .listItem_sub_arrow;
    }
}

.listItem_sub_sub {
    display: flex;
    background: $base-contrast-color;
    padding: 8px;
    border-radius: 8px;
    gap: 8px;
    &_content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &>svg {
        @extend .deleteIcon;
    }
}

.flex {
    display: flex;
    gap: 8px;
    &>div {
        width: 142px !important;
    }
}

.split {
    height: 1px;
    width: 100%;
    background: rgba(233, 0, 0, 0.534);
    margin: 24px 0;
}

.listOptions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &_subInput {
        display: flex;
        gap: 8px;
        &_add {
            @extend .listOptions_subInput;
            align-items: center;
            background: $base-color;
            border-radius: 8px;
            display: flex;
            gap: 8px;
            padding-left: 8px;
        }
    }
    &_subsubInput {
        padding-left: 18px;
        gap: 8px;
        display: flex;
        &_add {
            @extend .listOptions_subsubInput;
            align-items: center;
            background: $base-contrast-color;
            border-radius: 8px;
            display: flex;
            gap: 8px;
            padding-left: 8px;
        }
    }
    &_add {
        background-color: rgb(77, 204, 255);
        stroke: white;
        width: 16px;
        height: 16px;
        padding: 2px;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
        margin-top: 8px;
        margin-bottom: 8px;
        &>svg {
            transform: translate(-50%, -50%) rotate(45deg);
            top: 50%;
            left: 50%;
            position: absolute;
        }

        &_sub {
            @extend .listOptions_add;
        }
        &_subsub {
            @extend .listOptions_add;
        }
    }
}