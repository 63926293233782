.mail_card {
    padding: 24px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    text-align: center;
    gap: 24px;
    & p {
        font-weight: bold;
    }

    &_select {
        
    }

    &>div {
        display: flex;
        width: 100%;
        gap: 16px;
        justify-content: center;
    }
}
