@import './theme.scss';

.load {
    display: flex;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    max-width: 100vw;
    &_card {
        max-width: 80vw;
        @include card;
        background: $base-contrast-color;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 24px;
        padding: 32px 64px;
        &__text {
            font-weight: bold;
            color: $text-color;
            &_alt {
                color: $text-soft-color;
            }
        }
    }
}

.error {
    @extend .load;
    &_card {
        @extend .load_card;

        &_actions {
            display: flex;
            gap: 8px;
        }
        &__text {
            font-weight: bold;
            color: $text-color;
            &_alt {
                color: $text-soft-color;
            }
        }
        &__lock {
            height: 128px;
            fill: $text-color;
        }
    }
}

.layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    max-width: 100%;
    opacity: 0;
    animation: fadeIn;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    &__content {
        position: relative;
        flex: 1;
        background-color: $base-contrast-color;
    }
}

.lock {

}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.watermark {
    position: fixed;
    height: 100vh;
    background: transparent;
    color: red;
    width: 100vw;
    z-index: 99;
    font-size: 200px;
    justify-content: center;
    align-items: center;
    display: flex;
    pointer-events: none;
    opacity: 0.1;
    &>p {

    }
}