@import '../../theme';

.component {
    background: $base-color;
    height: $page-header-bar-size;
    display: flex;
    box-shadow: 0 0px 4px 1px rgb(0 0 0 / 13%);
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    font-size: 12px;
    position: relative;
    z-index: 3;
}

.Alert {
    opacity: 0.5;
    color: $warning-color;
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
    &>svg {
        fill: $warning-color;
        height: 20px;
    }
}


.logo {
    height: 24px;
}

.welcome {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
}

.section {
    gap: 16px;
    display: flex;
    align-items: center;
}

.signout {
    cursor: pointer;
    &:hover {
        color: $primary-color;
        text-decoration: underline;
    }
}

.burger {
    stroke: $text-color;
    height: 16px;
    width: 16px;

    &:hover {
        stroke: $primary-color;
        cursor: pointer;
    }
}

.cross {
    stroke: $text-color;
    height: 12px;
    width: 16px;
    &:hover {
        stroke: $primary-color;
        cursor: pointer;
    }
}

.spacer {
    width: 1px;
    height: 16px;
    background: $border-color;
}

.profile_icon {
    &:hover {
        outline: solid 1px $primary-color;
        cursor: pointer;
    }
    background: $base-contrast-color;
    height: 28px;
    border-radius: 50%;
    aspect-ratio: 1;
    position: relative;
    overflow: hidden;
}

.img_placeholder {
    width: 100%;
    height: 100%;
    background: $base-contrast-color;
    position: absolute;
    &_silhouette {
        fill: $base-color;
        position: absolute;
        height: 80%;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0%);
    }
}