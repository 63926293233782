@import '../../../theme.scss';

.component {
    padding: 8px 8px 8px 8px;
    display: flex;
    gap: 32px;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    overflow: hidden;
}

.link {
    min-width: 550px;
    @include link;
    white-space: nowrap;
    padding-right: 24px;
    cursor: pointer;
}

.title {
    font-weight: bold;
}

.content {
    max-width: 80vw;
    min-width: 45vw;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    gap: 16px;
    height: 70vh;
    @include smallScrollBar;
    &_scroll_conainer {
        padding-bottom: 24px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: absolute;
        flex: 1;
    }
}

.footer {
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
    &>div {
        display: flex;
        gap: 16px;
    }
}

.colorSelect {
    display: flex;
    gap: 8px;
    align-items: center;
}

.delete_card {
    padding: 24px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    text-align: center;
    gap: 24px;
    & span {
        font-weight: bold;
    }

    &>div {
        display: flex;
        width: 100%;
        gap: 16px;
        justify-content: center;
    }
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    background: $base-color;
    opacity: 0.75;
    &>svg {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
}

.vertical_inputs {
    display: flex;
    gap: 16px;
}