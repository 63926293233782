@import '../../theme.scss';

.component {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    position: relative;

    & input, textarea, select {
        font-family: main;
        padding: 8px 8px;
        border: none;
        border-radius: 4px;
        border: $border-color 1px solid;
        font-size: 12px;
        letter-spacing: 2px;
        background-color: $base-contrast-alt-color;
        color: $text-color;

        &:focus {
            color: $text-color;
            outline: none;
            border-color: $primary-color !important;
        }

        &:disabled {
            background-color: $disabled-color !important;
            opacity: 0.9;
            -webkit-appearance: none;
            -moz-appearance: none;
        }
    }

    & textarea {
        resize: none;
    }

    & select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: $base-contrast-alt-color;
        background-position: calc(100% - 10px) center !important;
        @include smallScrollBar;
        &:disabled {
            background-color: $disabled-color !important;
            opacity: 0.9;
            -moz-appearance:none !important;
            -webkit-appearance: none !important; 
            appearance: none !important;
        }

        &>option {
            font-size: 14px;
        }
    }

    & label {
        padding: 0 0 6px 2px;
        font-size: 12px;
        white-space: nowrap;
    }
}

.link {
    width: 100%;
    display: flex;
    &>input {
        cursor: pointer;
        flex: 1;
        @include link;
    }
}

.WithResetButton {
    padding: 7px 36px 9px 16px !important;
}

.withoutResetButton {
    padding: 8px 8px !important;
}

.search {
    font-size: 14px !important;
    border-radius: 24px !important;
    width: 240px;
    background: $base-contrast-alt-color;
    &:focus {
        color: $text-color !important;
        border-color: $primary-color !important;
        &::placeholder {
            color: $primary-color;
        }
    }
}

.input_error_msg {
    color: $primary-color;
    font-size: 11px;
    padding-bottom: 8px;
}

.input_error {
    border-bottom: 1px solid $primary-color !important;
}

.resetSearch {
    position: absolute;
    stroke: $text-color;
    height: 14px;
    right: 14px;
    transform: translateY(-50%);
    top: 50%;
    background: $base-contrast-alt-color;
    padding: 8px 0 8px 8px;
    &:hover {
        stroke: $primary-color;
        cursor: pointer;
    }
}