@import '../../../theme.scss';

.component {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.scrollContainer {
    @include smallScrollBar;
    overflow: auto;
}