@import '../../theme.scss';

.page {
    @include page;
}

.component {
    @include card;
    height: 100%;
    background: $base-color;
    display: flex;
    flex-direction: column;
    padding: 12px;
}

.copy {
    &:hover {
        fill: $primary-color;
    }
}