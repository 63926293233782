@import '../../theme.scss';

.component {
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // width: 100%;
    // height: 100%;
    background: $overlay-color;

    & .card {
        // @include smallScrollBar;
        
        // max-width: 1200px;
        display: flex;
        flex-direction: column;
        // overflow-y: auto;
        // padding: 32px;
        border-radius: 4px;
        background: $base-color;
        position: fixed;
        top: 50%;
        left: calc(50% + calc(112px / 2));
        height: 92%;
        width: calc(92% - 112px);
        transform: translate(-50%, -50%);
        box-shadow: 0 0px 8px 1px rgb(0 0 0 / 18%);
    }
}

.close_btn {
    right: 16px;
    height: 12px;
    position: absolute;
    z-index: 4;
    stroke: $text-soft-color;
    cursor: pointer;
    &:hover {
        stroke: $primary-color;
    }
}

.componentTest {
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $overlay-color;
}

.cardTest {
    @include card;
    padding: 12px;
    position: fixed;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - calc(16px + 16px + 32px));
    max-width: calc(100% - calc(16px + 16px + 64px));
    &_adjusted {
        left: calc(50% + calc($sidebar-width / 2));
        max-width: calc(calc(100% - $sidebar-width) - calc(16px + 16px + 64px));
    }
    &_big {
        height: 100%;
        width: 100%;
    }
}